<template>
  <div>
    <h2>{{ $t("statistics-title") }}</h2>
    <div class="row">
      <div class="col-6">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startDate"
              :label="$t('start-date')"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            @input="menu = false"
            :max="date2"
          ></v-date-picker>
        </v-menu>
      </div>
      <div class="col-6">
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-left="160"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="endDate"
              :label="$t('end-date')"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date2"
            @input="menu2 = false"
            :min="date"
            :max="today"
          ></v-date-picker>
        </v-menu>
      </div>
      <div class="col-6" v-if="downline">
        <v-autocomplete
          v-model="user"
          :filter="filterObject"
          :label="$t('user')"
          hide-selected
          clearable
          dense
          outlined
          :items="downline.viewer.downline"
          item-value="id"
          item-text="username"
        >
          <template v-slot:item="{ props, item }">
            <v-list-item v-bind="props">
              <v-list-item-content>
                <div style="font-weight: bold">{{ item.username }}</div>
                <div style="color: #c0c0c0">
                  {{ item.firstName }} {{ item.lastName }}
                </div>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
      </div>
      <div class="col-6">
        <v-btn class="text-unset" small color="success" @click="update">{{
          $t("update")
        }}</v-btn>
      </div>
      <div class="col-12 col-lg-6">
        <div class="card mb-3 statistics-table centered-elem" v-if="page">
          <div
            style="
              text-align: center;
              background-color: #028a0f;
              color: white;
              font-size: x-large;
            "
          >
            ALL volume
          </div>
          <table class="statistics-table" style="border: ">
            <tr class="stats-row" rowspan="1">
              <td class="w-33" style="background-color: lightgray">
                {{ $t("left") }}
              </td>
              <td
                class="w-33 text-center centered-elem"
                style="background-color: lightgray"
              >
                {{ $t("total") }}
              </td>
              <td class="w-33 text-right" style="background-color: lightgray">
                {{ $t("right") }}
              </td>
            </tr>
            <tr class="empty-cell"></tr>
            <tr class="title-row">
              <td colspan="3" class="stats-title">
                {{ $t("direct-packages") }}
              </td>
            </tr>

            <tr class="stats-row">
              <td>
                {{ page.statistics.left.directPackages.packages }} |
                <span class="d-inline-block"
                  >{{ page.statistics.left.directPackages.volume }} €</span
                >
              </td>
              <td class="text-center centered-elem">
                {{ page.statistics.total.directPackages.packages }} |
                <span class="d-inline-block"
                  >{{ page.statistics.total.directPackages.volume }} €</span
                >
              </td>
              <td class="text-right">
                {{ page.statistics.right.directPackages.packages }} |
                <span class="d-inline-block"
                  >{{ page.statistics.right.directPackages.volume }} €</span
                >
              </td>
            </tr>
            <tr class="empty-cell"></tr>
            <tr class="title-row">
              <td colspan="3" class="stats-title">
                {{ $t("direct-upgrades") }}
              </td>
            </tr>
            <tr class="stats-row">
              <td>
                {{ page.statistics.left.directUpgrades.packages }} |
                <span class="d-inline-block"
                  >{{ page.statistics.left.directUpgrades.volume }} €</span
                >
              </td>
              <td class="text-center centered-elem">
                {{ page.statistics.total.directUpgrades.packages }} |
                <span class="d-inline-block"
                  >{{ page.statistics.total.directUpgrades.volume }} €</span
                >
              </td>
              <td class="text-right">
                {{ page.statistics.right.directUpgrades.packages }} |
                <span class="d-inline-block"
                  >{{ page.statistics.right.directUpgrades.volume }} €</span
                >
              </td>
            </tr>
            <tr class="empty-cell"></tr>
            <tr class="title-row">
              <td colspan="3" class="stats-title">
                Direct Freedom program with Volume €
              </td>
            </tr>
            <tr class="stats-row">
              <td>
                {{ page.statistics.left.directFreedom.packages }} |
                <span class="d-inline-block"
                  >{{ page.statistics.left.directFreedom.volume }} €</span
                >
              </td>
              <td class="text-center centered-elem">
                {{ page.statistics.total.directFreedom.packages }} |
                <span class="d-inline-block"
                  >{{ page.statistics.total.directFreedom.volume }} €</span
                >
              </td>
              <td class="text-right">
                {{ page.statistics.right.directFreedom.packages }} |
                <span class="d-inline-block"
                  >{{ page.statistics.right.directFreedom.volume }} €</span
                >
              </td>
            </tr>
            <tr class="empty-cell"></tr>

            <tr class="title-row" style="background-color: #3ded97">
              <td colspan="3" class="stats-title">
                Total from directs with Volume €
              </td>
            </tr>
            <tr class="stats-row" style="background-color: #3ded97">
              <td>
                {{ page.statistics.left.directSUM.packages }} |
                <span class="d-inline-block"
                  >{{ page.statistics.left.directSUM.volume }} €</span
                >
              </td>
              <td class="text-center centered-elem">
                {{ page.statistics.total.directSUM.packages }} |
                <span class="d-inline-block"
                  >{{ page.statistics.total.directSUM.volume }} €</span
                >
              </td>
              <td class="text-right">
                {{ page.statistics.right.directSUM.packages }} |
                <span class="d-inline-block"
                  >{{ page.statistics.right.directSUM.volume }} €</span
                >
              </td>
            </tr>
            <tr class="empty-cell"></tr>

            <tr class="title-row">
              <td colspan="3" class="stats-title">
                {{ $t("group-packages") }}
              </td>
            </tr>
            <tr class="stats-row">
              <td>
                {{ page.statistics.left.groupPackages.packages }} |
                <span class="d-inline-block"
                  >{{ page.statistics.left.groupPackages.volume }} €</span
                >
              </td>
              <td class="text-center centered-elem">
                {{ page.statistics.total.groupPackages.packages }} |
                <span class="d-inline-block"
                  >{{ page.statistics.total.groupPackages.volume }} €</span
                >
              </td>
              <td class="text-right">
                {{ page.statistics.right.groupPackages.packages }} |
                <span class="d-inline-block"
                  >{{ page.statistics.right.groupPackages.volume }} €</span
                >
              </td>
            </tr>
            <tr class="empty-cell"></tr>
            <tr class="title-row">
              <td colspan="3" class="stats-title">
                {{ $t("group-upgrades") }}
              </td>
            </tr>
            <tr class="stats-row">
              <td>
                {{ page.statistics.left.groupUpgrades.packages }} |
                <span class="d-inline-block"
                  >{{ page.statistics.left.groupUpgrades.volume }} €</span
                >
              </td>
              <td class="text-center centered-elem">
                {{ page.statistics.total.groupUpgrades.packages }} |
                <span class="d-inline-block"
                  >{{ page.statistics.total.groupUpgrades.volume }} €</span
                >
              </td>
              <td class="text-right">
                {{ page.statistics.right.groupUpgrades.packages }} |
                <span class="d-inline-block"
                  >{{ page.statistics.right.groupUpgrades.volume }} €</span
                >
              </td>
            </tr>
            <tr class="empty-cell"></tr>

            <tr class="title-row">
              <td colspan="3" class="stats-title">
                Group Freedom program with Volume €
              </td>
            </tr>
            <tr class="stats-row">
              <td>
                {{ page.statistics.left.groupFreedom.packages }} |
                <span class="d-inline-block"
                  >{{ page.statistics.left.groupFreedom.volume }} €</span
                >
              </td>
              <td class="text-center centered-elem">
                {{ page.statistics.total.groupFreedom.packages }} |
                <span class="d-inline-block"
                  >{{ page.statistics.total.groupFreedom.volume }} €</span
                >
              </td>
              <td class="text-right">
                {{ page.statistics.right.groupFreedom.packages }} |
                <span class="d-inline-block"
                  >{{ page.statistics.right.groupFreedom.volume }} €</span
                >
              </td>
            </tr>
            <tr class="empty-cell"></tr>

            <tr class="title-row" style="background-color: #3ded97">
              <td colspan="3" class="stats-title">
                Total from group with Volume €
              </td>
            </tr>
            <tr class="stats-row" style="background-color: #3ded97">
              <td>
                {{ page.statistics.left.groupSUM.packages }} |
                <span class="d-inline-block"
                  >{{ page.statistics.left.groupSUM.volume }} €</span
                >
              </td>
              <td class="text-center centered-elem">
                {{ page.statistics.total.groupSUM.packages }} |
                <span class="d-inline-block"
                  >{{ page.statistics.total.groupSUM.volume }} €</span
                >
              </td>
              <td class="text-right">
                {{ page.statistics.right.groupSUM.packages }} |
                <span class="d-inline-block"
                  >{{ page.statistics.right.groupSUM.volume }} €</span
                >
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="col-12 col-lg-6">
        <div class="card mb-3 statistics-table centered-elem" v-if="page">
          <div
            style="
              text-align: center;
              background-color: #187bcd;
              color: white;
              font-size: x-large;
            "
          >
            TICKETS
          </div>
          <div>
            <select
              name="TicketType"
              id="TicketType"
              @change="onTicketTypeChange($event)"
              v-model="ticketType"
              style="width: 100%; font-size: large; -webkit-appearance: auto"
            >
              <option value="1">Spring Weekend Seminar 2021</option>
              <!-- <option value="2">Fall Weekend Seminar 2021</option> -->
              <option value="3">Autumn Weekend Seminar 2022</option>
              <option value="4">Spring Weekend Seminar 2022</option>
              <option value="5">Winter Weekend Seminar 2023</option>
              <option value="6">Summer Weekend Seminar 2023</option>
              <option value="7">Autumn Weekend Seminar 2023</option>
              <option value="8">Winter Weekend Seminar 2024</option>
              <option value="9">Summer Weekend Seminar 2024</option>
              <option value="10">Autumn Weekend Seminar 2024</option>
              <option value="11">Spring Weekend Seminar 2025</option>
              <option value="12">Summer Weekend Seminar 2025</option>
            </select>
          </div>
          <table class="statistics-table">
            <tr class="stats-row" rowspan="1">
              <td class="w-33" style="background-color: lightgray">
                {{ $t("left") }}
              </td>
              <td
                class="w-33 text-center centered-elem"
                style="background-color: lightgray"
              >
                {{ $t("total") }}
              </td>
              <td class="w-33 text-right" style="background-color: lightgray">
                {{ $t("right") }}
              </td>
            </tr>

            <tr class="empty-cell"></tr>
            <tr class="title-row">
              <td colspan="3" class="stats-title">Personal Tickets</td>
            </tr>
            <tr class="stats-row">
              <td colspan="3" class="text-center centered-elem">
                <span class="d-inline-block">{{ this.ticketsX.own }} </span>
              </td>
            </tr>

            <tr class="empty-cell"></tr>
            <tr class="title-row">
              <td colspan="3" class="stats-title">Direct Tickets</td>
            </tr>
            <tr class="stats-row">
              <td>{{ this.ticketsX.directLeft }}</td>
              <td class="text-center centered-elem">
                {{ this.ticketsX.directLeft + this.ticketsX.directRight }}
              </td>
              <td class="text-right">{{ this.ticketsX.directRight }}</td>
            </tr>

            <tr class="empty-cell"></tr>
            <tr class="title-row">
              <td colspan="3" class="stats-title">Direct + Group Tickets</td>
            </tr>
            <tr class="stats-row">
              <td>{{ this.ticketsX.groupLeft }}</td>
              <td class="text-center centered-elem">
                {{ this.ticketsX.groupLeft + this.ticketsX.groupRight }}
              </td>
              <td class="text-right">{{ this.ticketsX.groupRight }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import gql from "graphql-tag";
import moment from "moment";
export default {
  name: "Statistics",
  data: () => ({
    ticketType: "12", //ticketSummerTwentyFive je 11
    date: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    date2: new Date().toISOString().substr(0, 10),
    today: new Date().toISOString().substr(0, 10),
    menu2: false,
    user: "",
    page: null,
    downline: null,
    ticketsX: {},
  }),
  computed: {
    startDate() {
      return moment(String(this.date)).format("D. MMM YYYY");
    },
    endDate() {
      return moment(String(this.date2)).format("D. MMM YYYY");
    },
  },
  apollo: {
    page: {
      query: gql`
        query ($userId: ID, $start: DateTime, $end: DateTime) {
          statistics(userId: $userId, start: $start, end: $end) {
            total {
              directPackages {
                packages
                volume
              }
              directUpgrades {
                packages
                volume
              }
              groupPackages {
                packages
                volume
              }
              groupUpgrades {
                packages
                volume
              }
              directFreedom {
                packages
                volume
              }
              groupFreedom {
                packages
                volume
              }
              directSUM {
                packages
                volume
              }
              groupSUM {
                packages
                volume
              }
            }
            left {
              directPackages {
                packages
                volume
              }
              directUpgrades {
                packages
                volume
              }
              groupPackages {
                packages
                volume
              }
              groupUpgrades {
                packages
                volume
              }
              directFreedom {
                packages
                volume
              }
              groupFreedom {
                packages
                volume
              }
              directSUM {
                packages
                volume
              }
              groupSUM {
                packages
                volume
              }
            }
            right {
              directPackages {
                packages
                volume
              }
              directUpgrades {
                packages
                volume
              }
              groupPackages {
                packages
                volume
              }
              groupUpgrades {
                packages
                volume
              }
              directFreedom {
                packages
                volume
              }
              groupFreedom {
                packages
                volume
              }
              directSUM {
                packages
                volume
              }
              groupSUM {
                packages
                volume
              }
            }
            tickets {
              ticketSpringTwentyOne {
                own
                directLeft
                directRight
                groupLeft
                groupRight
              }
              ticketFallTwentyOne {
                own
                directLeft
                directRight
                groupLeft
                groupRight
              }
              ticketAutumnTwentyTwo {
                own
                directLeft
                directRight
                groupLeft
                groupRight
              }
              ticketSpringTwentyTwo {
                own
                directLeft
                directRight
                groupLeft
                groupRight
              }
              ticketWinterTwentyThree {
                own
                directLeft
                directRight
                groupLeft
                groupRight
              }
              ticketSummerTwentyThree {
                own
                directLeft
                directRight
                groupLeft
                groupRight
              }
              ticketAutumnTwentyThree {
                own
                directLeft
                directRight
                groupLeft
                groupRight
              }
              ticketWinterTwentyFour {
                own
                directLeft
                directRight
                groupLeft
                groupRight
              }
              ticketSummerTwentyFour {
                own
                directLeft
                directRight
                groupLeft
                groupRight
              }
              ticketAutumnTwentyFour {
                own
                directLeft
                directRight
                groupLeft
                groupRight
              }
              ticketSpringTwentyFive {
                own
                directLeft
                directRight
                groupLeft
                groupRight
              }
              ticketSummerTwentyFive {
                own
                directLeft
                directRight
                groupLeft
                groupRight
              }
              own
              directLeft
              directRight
              groupLeft
              groupRight
            }
          }
        }
      `,
      variables: {},

      update: (d) => {
        return d;
      },
    },
    downline: {
      query: gql`
        query {
          viewer {
            id
            downline {
              id
              username
              firstName
              lastName
            }
          }
        }
      `,
      update: (data) => data,
    },
  },
  watch: {
    page: function (val) {
      this.ticketsX = val.statistics.tickets;
      this.updateTickets();
    },
  },
  methods: {
    filterObject(item, queryText, itemText) {
      return (
        item.username
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.firstName
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.lastName
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    onTicketTypeChange(event) {
      this.updateTickets();
    },
    updateTickets() {
      switch (this.ticketType) {
        case "1": {
          //Spring Weekend Seminar 2021
          this.ticketsX.own = this.ticketsX.ticketSpringTwentyOne.own;
          this.ticketsX.directLeft =
            this.ticketsX.ticketSpringTwentyOne.directLeft;
          this.ticketsX.directRight =
            this.ticketsX.ticketSpringTwentyOne.directRight;
          this.ticketsX.groupLeft =
            this.ticketsX.ticketSpringTwentyOne.groupLeft;
          this.ticketsX.groupRight =
            this.ticketsX.ticketSpringTwentyOne.groupRight;
          break;
        }
        case "2": {
          //Fall Weekend Seminar 2021
          this.ticketsX.own = this.ticketsX.ticketFallTwentyOne.own;
          this.ticketsX.directLeft =
            this.ticketsX.ticketFallTwentyOne.directLeft;
          this.ticketsX.directRight =
            this.ticketsX.ticketFallTwentyOne.directRight;
          this.ticketsX.groupLeft = this.ticketsX.ticketFallTwentyOne.groupLeft;
          this.ticketsX.groupRight =
            this.ticketsX.ticketFallTwentyOne.groupRight;
          break;
        }
        case "3": {
          //Autumn Weekend Seminar 2022
          this.ticketsX.own = this.ticketsX.ticketAutumnTwentyTwo.own;
          this.ticketsX.directLeft =
            this.ticketsX.ticketAutumnTwentyTwo.directLeft;
          this.ticketsX.directRight =
            this.ticketsX.ticketAutumnTwentyTwo.directRight;
          this.ticketsX.groupLeft =
            this.ticketsX.ticketAutumnTwentyTwo.groupLeft;
          this.ticketsX.groupRight =
            this.ticketsX.ticketAutumnTwentyTwo.groupRight;
          break;
        }
        case "4": {
          //Spring Weekend Seminar 2022
          this.ticketsX.own = this.ticketsX.ticketSpringTwentyTwo.own;
          this.ticketsX.directLeft =
            this.ticketsX.ticketSpringTwentyTwo.directLeft;
          this.ticketsX.directRight =
            this.ticketsX.ticketSpringTwentyTwo.directRight;
          this.ticketsX.groupLeft =
            this.ticketsX.ticketSpringTwentyTwo.groupLeft;
          this.ticketsX.groupRight =
            this.ticketsX.ticketSpringTwentyTwo.groupRight;
          break;
        }
        case "5": {
          //Winter Weekend Seminar 2023
          this.ticketsX.own = this.ticketsX.ticketWinterTwentyThree.own;
          this.ticketsX.directLeft =
            this.ticketsX.ticketWinterTwentyThree.directLeft;
          this.ticketsX.directRight =
            this.ticketsX.ticketWinterTwentyThree.directRight;
          this.ticketsX.groupLeft =
            this.ticketsX.ticketWinterTwentyThree.groupLeft;
          this.ticketsX.groupRight =
            this.ticketsX.ticketWinterTwentyThree.groupRight;
          break;
        }
        case "6": {
          //Summer Weekend Seminar 2023
          this.ticketsX.own = this.ticketsX.ticketSummerTwentyThree.own;
          this.ticketsX.directLeft =
            this.ticketsX.ticketSummerTwentyThree.directLeft;
          this.ticketsX.directRight =
            this.ticketsX.ticketSummerTwentyThree.directRight;
          this.ticketsX.groupLeft =
            this.ticketsX.ticketSummerTwentyThree.groupLeft;
          this.ticketsX.groupRight =
            this.ticketsX.ticketSummerTwentyThree.groupRight;
          break;
        }
        case "7": {
          //Autumn Weekend Seminar 2023
          this.ticketsX.own = this.ticketsX.ticketAutumnTwentyThree.own;
          this.ticketsX.directLeft =
            this.ticketsX.ticketAutumnTwentyThree.directLeft;
          this.ticketsX.directRight =
            this.ticketsX.ticketAutumnTwentyThree.directRight;
          this.ticketsX.groupLeft =
            this.ticketsX.ticketAutumnTwentyThree.groupLeft;
          this.ticketsX.groupRight =
            this.ticketsX.ticketAutumnTwentyThree.groupRight;

          break;
        }
        case "8": {
          //Winter Weekend Seminar 2024
          this.ticketsX.own = this.ticketsX.ticketWinterTwentyFour.own;
          this.ticketsX.directLeft =
            this.ticketsX.ticketWinterTwentyFour.directLeft;
          this.ticketsX.directRight =
            this.ticketsX.ticketWinterTwentyFour.directRight;
          this.ticketsX.groupLeft =
            this.ticketsX.ticketWinterTwentyFour.groupLeft;
          this.ticketsX.groupRight =
            this.ticketsX.ticketWinterTwentyFour.groupRight;

          break;
        }
        case "9": {
          //Summer Weekend Seminar 2024
          this.ticketsX.own = this.ticketsX.ticketSummerTwentyFour.own;
          this.ticketsX.directLeft =
            this.ticketsX.ticketSummerTwentyFour.directLeft;
          this.ticketsX.directRight =
            this.ticketsX.ticketSummerTwentyFour.directRight;
          this.ticketsX.groupLeft =
            this.ticketsX.ticketSummerTwentyFour.groupLeft;
          this.ticketsX.groupRight =
            this.ticketsX.ticketSummerTwentyFour.groupRight;

          break;
        }
        case "10": {
          //Autumn Weekend Seminar 2024
          this.ticketsX.own = this.ticketsX.ticketAutumnTwentyFour.own;
          this.ticketsX.directLeft =
            this.ticketsX.ticketAutumnTwentyFour.directLeft;
          this.ticketsX.directRight =
            this.ticketsX.ticketAutumnTwentyFour.directRight;
          this.ticketsX.groupLeft =
            this.ticketsX.ticketAutumnTwentyFour.groupLeft;
          this.ticketsX.groupRight =
            this.ticketsX.ticketAutumnTwentyFour.groupRight;
          break;
        }
        case "11": {
          //Spring Weekend Seminar 2025
          this.ticketsX.own = this.ticketsX.ticketSpringTwentyFive.own;
          this.ticketsX.directLeft =
            this.ticketsX.ticketSpringTwentyFive.directLeft;
          this.ticketsX.directRight =
            this.ticketsX.ticketSpringTwentyFive.directRight;
          this.ticketsX.groupLeft =
            this.ticketsX.ticketSpringTwentyFive.groupLeft;
          this.ticketsX.groupRight =
            this.ticketsX.ticketSpringTwentyFive.groupRight;
          break;
        }
        case "12": {
          //Summer Weekend Seminar 2025
          this.ticketsX.own = this.ticketsX.ticketSummerTwentyFive.own;
          this.ticketsX.directLeft =
            this.ticketsX.ticketSummerTwentyFive.directLeft;
          this.ticketsX.directRight =
            this.ticketsX.ticketSummerTwentyFive.directRight;
          this.ticketsX.groupLeft =
            this.ticketsX.ticketSummerTwentyFive.groupLeft;
          this.ticketsX.groupRight =
            this.ticketsX.ticketSummerTwentyFive.groupRight;
          break;
        }
      }
    },

    update() {
      this.$apollo
        .query({
          query: gql`
            query ($userId: ID, $start: DateTime, $end: DateTime) {
              statistics(userId: $userId, start: $start, end: $end) {
                total {
                  directPackages {
                    packages
                    volume
                  }
                  directUpgrades {
                    packages
                    volume
                  }
                  groupPackages {
                    packages
                    volume
                  }
                  groupUpgrades {
                    packages
                    volume
                  }
                  directFreedom {
                    packages
                    volume
                  }
                  groupFreedom {
                    packages
                    volume
                  }
                  directSUM {
                    packages
                    volume
                  }
                  groupSUM {
                    packages
                    volume
                  }
                }
                left {
                  directPackages {
                    packages
                    volume
                  }
                  directUpgrades {
                    packages
                    volume
                  }
                  groupPackages {
                    packages
                    volume
                  }
                  groupUpgrades {
                    packages
                    volume
                  }
                  directFreedom {
                    packages
                    volume
                  }
                  groupFreedom {
                    packages
                    volume
                  }
                  directSUM {
                    packages
                    volume
                  }
                  groupSUM {
                    packages
                    volume
                  }
                }
                right {
                  directPackages {
                    packages
                    volume
                  }
                  directUpgrades {
                    packages
                    volume
                  }
                  groupPackages {
                    packages
                    volume
                  }
                  groupUpgrades {
                    packages
                    volume
                  }
                  directFreedom {
                    packages
                    volume
                  }
                  groupFreedom {
                    packages
                    volume
                  }
                  directSUM {
                    packages
                    volume
                  }
                  groupSUM {
                    packages
                    volume
                  }
                }
                tickets {
                  ticketSpringTwentyOne {
                    own
                    directLeft
                    directRight
                    groupLeft
                    groupRight
                  }
                  ticketFallTwentyOne {
                    own
                    directLeft
                    directRight
                    groupLeft
                    groupRight
                  }
                  ticketAutumnTwentyTwo {
                    own
                    directLeft
                    directRight
                    groupLeft
                    groupRight
                  }
                  ticketSpringTwentyTwo {
                    own
                    directLeft
                    directRight
                    groupLeft
                    groupRight
                  }
                  ticketWinterTwentyThree {
                    own
                    directLeft
                    directRight
                    groupLeft
                    groupRight
                  }
                  ticketSummerTwentyThree {
                    own
                    directLeft
                    directRight
                    groupLeft
                    groupRight
                  }
                  ticketAutumnTwentyThree {
                    own
                    directLeft
                    directRight
                    groupLeft
                    groupRight
                  }
                  ticketWinterTwentyFour {
                    own
                    directLeft
                    directRight
                    groupLeft
                    groupRight
                  }
                  ticketSummerTwentyFour {
                    own
                    directLeft
                    directRight
                    groupLeft
                    groupRight
                  }
                  ticketAutumnTwentyFour {
                    own
                    directLeft
                    directRight
                    groupLeft
                    groupRight
                  }
                  ticketSpringTwentyFive {
                    own
                    directLeft
                    directRight
                    groupLeft
                    groupRight
                  }
                  ticketSummerTwentyFive {
                    own
                    directLeft
                    directRight
                    groupLeft
                    groupRight
                  }
                  own
                  directLeft
                  directRight
                  groupLeft
                  groupRight
                }
              }
            }
          `,

          variables: {
            userId: this.user,
            start: new Date(this.date),
            end: new Date(
              new Date(this.date2).setDate(new Date(this.date2).getDate() + 1)
            ),
          },
        })
        .then((resp) => {
          this.page = resp.data;
        });
    },
  },
};
</script>
<style scoped>
.stats-row {
  margin-bottom: 1.2rem;
}

.stats-row > td {
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0.5rem 0.8rem;
}

.stats-title {
  opacity: 0.6;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
}

.centered-elem {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 0px;
  border-top: 0px;
}

.title-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.stats-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.empty-cell {
  height: 1.8rem;
}

.w-33 {
  width: 33% !important;
}
</style>